@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: Poppins;
  src: url(./assets/fonts/Poppins-Regular.ttf);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.active {
  background-color: #667a8a;
  color: white;
}

* {
  font-family: Poppins !important;
}

.hover {
  background-color: #0078d4;
}

.ant-btn-primary {
  background-color: #1677ff !important;
}

.dateCell {
  position: relative;
}

.dateCell:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  max-width: 40px;
  max-height: 40px;
  background: transparent;
  transition: background 300ms;
  border-radius: 4px;
  border: 1px solid transparent;
  box-sizing: border-box;
}

.dateCell:hover:before {
  background: rgba(0, 0, 0, 0.04);
}

.today:before {
  border: 1px solid #1677ff;
}

.text {
  position: relative;
  z-index: 1;
}

.current {
  color: var(--colorTextLightSolid);
}

.current:before {
  background: #1677ff;
}

.current:hover:before {
  background: #1677ff;
  opacity: 0.8;
}

.scheduled {
  color: var(--colorTextLightSolid);
}

.scheduled:before {
  background: #f24;
}

.scheduled:hover:before {
  background: #f24;
  opacity: 0.8;
}

.monthCell {
  color: var(--colorTextBase);
  border-radius: 4px;
  padding: 5px;
}

.monthCell:hover {
  background: rgba(0, 0, 0, 0.04);
}

.monthCellCurrent {
  color: var(--colorTextLightSolid);
  background: #1677ff;
}

.monthCellCurrent:hover {
  background: #1677ff;
  opacity: 0.8;
}

.login-back {
  background: linear-gradient(90deg, rgb(0, 0, 0) 0%, rgb(53, 51, 205) 100%);
}
