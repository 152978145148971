.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.login-form {
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-form-forgot {
  float: left;
}

.ant-col-rtl .login-form-forgot {
  float: left;
}

.login-form-button {
  width: 100%;
}

.navbar-item {
  display: flex;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-radius: 0.375rem;
  gap: 0.5rem;
  justify-content: flex-start;
  align-items: center;
}

.btn {
  padding-left: 1rem;
  padding-right: 1rem;
  border-width: 1px;
  border-radius: 0.375rem;
  height: 36px;
}

.w-navbar {
  width: 100%;
}

@media only screen and (min-width: 768px) {
  .w-navbar {
    width: calc(100% - 250px);
  }
}

::-webkit-scrollbar {
  cursor: pointer;
  width: 2px;
  height: 8px;
  border-radius: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  cursor: pointer;
  background: #3b415c;
}

/* Handle */
::-webkit-scrollbar-thumb {
  cursor: pointer;
  background: #4b308b;
  border-radius: 2px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #613ce5;
}

.ant-input-affix-wrapper:focus {
  background-color: #ffffff;
}

.ant-input-affix-wrapper:focus-within {
  background-color: #ffffff;
}

.ant-input:focus-within {
  background-color: #ffffff;
}

.ant-input:focus {
  background-color: #ffffff;
}

.ant-input-affix-wrapper:hover {
  background-color: #ffffff;
}

.ant-input:hover {
  background-color: #ffffff;
}

.login-img {
  object-fit: cover;
  display: none;
  border-radius: 40px 0 0 40px;
}

@media only screen and (min-width: 768px) {
  .login-img {
    display: block;
    max-width: 100vw !important;
    max-height: 80vh;
    min-height: 45vh;
    width: calc(100vw - 528px);
  }
}
